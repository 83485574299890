body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "ABCFavorit";
  font-weight: 500;
  src: local("ABCFavorit"),
    url(../../public/fonts/ABCFavorit-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "ABCFavorit";
  font-weight: 600;
  src: local("ABCFavorit"),
    url(../../public/fonts/ABCFavorit-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "ABCFavorit";
  font-weight: 700;
  src: local("ABCFavorit"),
    url(../../public/fonts/ABCFavorit-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "NBIMono";
  font-weight: 400;
  src: local("NBIMono"),
    url(../../public/fonts/nbinternationalpromono-webfont.woff2) format("woff2");
}

@font-face {
  font-family: "NBIRegular";
  font-weight: 400;
  src: local("NBIRegular"),
    url(../../public/fonts/nbinternationalproreg-webfont.woff2) format("woff2");
}

@font-face {
  font-family: "NBIMedium";
  font-weight: 500;
  src: local("NBIMedium"),
    url(../../public/fonts/nbinternationalpromed-webfont.woff2) format("woff2");
}

@font-face {
  font-family: "NBIBold";
  font-weight: 700;
  src: local("NBIBold"),
    url(../../public/fonts/nbinternationalprobol-webfont.woff2) format("woff2");
}
